<template>
  <div>
    <b-button
      native-type="button"
      type="is-success"
      expanded
      @click="isModalActive = true"
    >
      {{ buttonText }}
    </b-button>
    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ isEdit ? 'Edit User' : 'Add User' }}
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field
            horizontal
            label="First Name"
            :type="{ 'is-danger': errors.first_name }"
            :message="{ [errors.first_name ? errors.first_name[0] : '']: errors.first_name }"
          >
            <b-input
              v-model="form.first_name"
              name="first_name"
              required
            />
          </b-field>
          <b-field
            horizontal
            label="Middle Name"
            :type="{ 'is-danger': errors.middle_name }"
            :message="{ [errors.first_name ? errors.middle_name[0] : '']: errors.middle_name }"
          >
            <b-input
              v-model="form.middle_name"
              name="middle_name"
            />
          </b-field>
          <b-field
            horizontal
            label="Last Name"
            :type="{ 'is-danger': errors.last_name }"
            :message="{ [errors.last_name ? errors.last_name[0] : '']: errors.last_name }"
          >
            <b-input
              v-model="form.last_name"
              name="last_name"
              required
            />
          </b-field>
          <b-field
            horizontal
            label="E-mail"
            :type="{ 'is-danger': errors.email }"
            :message="{ [errors.email ? errors.email[0] : '']: errors.email }"
          >
            <b-input
              v-model="form.email"
              name="email"
              type="email"
              required
            />
          </b-field>
          <b-field
            horizontal
            label="Phone"
            :type="{ 'is-danger': errors.phone }"
            :message="{ [errors.phone ? errors.phone[0] : '']: errors.phone }"
          >
            <vue-tel-input
              v-model="form.phone"
              :only-countries="['KE', 'US']"
              @input="onPhoneInput"
            />
          </b-field>
          <b-field
            horizontal
            label="Role"
            :type="{ 'is-danger': errors.role }"
            :message="{ [errors.role ? errors.role[0] : '']: errors.role }"
          >
            <b-select
              v-model="form.role"
              placeholder="Select a role"
              expanded
            >
              <option value="user">
                User
              </option>
              <option value="driver">
                Driver
              </option>
              <option value="super-admin">
                Super Admin
              </option>
            </b-select>
          </b-field>
          <b-field
            horizontal
            label="Active Status"
            :type="{ 'is-danger': errors.status }"
            :message="{ [errors.status ? errors.status[0] : '']: errors.status }"
          >
            <b-switch
              v-model="form.status"
              type="is-info"
            />
          </b-field>
          <b-field
            horizontal
            label="Password"
            :type="{ 'is-danger': errors.password }"
            :message="{ [errors.password ? errors.password[0] : '']: errors.password }"
          >
            <b-input
              v-model="form.password"
              name="password"
              type="password"
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            v-if="isEdit"
            type="is-success"
            :loading="isLoading"
            @click="update"
          >
            {{ isEdit ? 'Update' : 'Create' }}
          </b-button>
          <b-button
            v-else
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            {{ isEdit ? 'Update' : 'Create' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ManageUsers',
  props: {
    buttonText: {
      type: String,
      default: 'Add User'
    },
    user: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      errors: {},
      isEdit: false,
      isModalActive: false,
      form: {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        dial_code: '',
        iso_code: '',
        phone_number: '',
        role: '',
        status: true
      }
    }
  },
  watch: {
    user: {
      handler () {
        if (this.user != null) {
          this.isEdit = true
          this.form.first_name = this.user.first_name
          this.form.middle_name = this.user.middle_name
          this.form.last_name = this.user.last_name
          this.form.email = this.user.email
          this.form.phone = this.user.dial_code + this.user.phone
          this.form.role = this.user.role
          this.form.status = this.user.is_active === 1
          this.form.dial_code = this.user.dial_code
          this.form.iso_code = this.user.iso_code
          this.form.phone_number = this.user.phone
        }
      },
      immediate: true
    }
  },
  methods: {
    update () {
      this.isLoading = true
      this.axios.patch(`/v2/super-admin/users/${this.user.id}`, {
        first_name: this.form.first_name,
        middle_name: this.form.middle_name,
        last_name: this.form.last_name,
        email: this.form.email,
        phone: this.form.phone_number,
        dial_code: this.form.dial_code,
        iso_code: this.form.iso_code,
        password: this.form.password,
        role: this.form.role,
        status: this.form.status
      })
        .then(() => {
          this.isLoading = false
          this.$emit('refresh')
          this.isModalActive = false
          this.isEdit = false
          this.$buefy.snackbar.open({
            message: 'User updated successfully',
            queue: false
          })
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    },
    submit () {
      this.isLoading = true
      this.axios.post('/v2/super-admin/users', this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.form = {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            dial_code: '',
            iso_code: '',
            phone_number: '',
            role: '',
            status: true
          }
          this.$buefy.snackbar.open({
            message: 'User created successfully',
            queue: false
          })
          this.$emit('refresh', 1)
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    },
    onPhoneInput (number, phoneObject) {
      if (!phoneObject.valid && number) {
        this.errors.phone = ['Invalid phone number']
        this.form.dial_code = ''
        this.form.iso_code = ''
        return
      }
      if (phoneObject.valid) {
        this.errors.phone = null
        this.form.dial_code = '+' + phoneObject.countryCallingCode
        this.form.iso_code = phoneObject.countryCode
        this.form.phone_number = phoneObject.nationalNumber
      }
    }
  }
}
</script>

<style>

</style>
