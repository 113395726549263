<template>
  <div v-if="user">
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="'Edit User'"
          icon="account-edit"
          class="tile is-child"
        >
          <manage-users
            :user="user"
            :button-text="'Edit User'"
            @refresh="fetchUser"
          />
        </card-component>
        <card-component
          title="User Profile"
          icon="account"
          class="tile is-child"
        >
          <user-avatar
            :avatar="'https://ui-avatars.com/api/?background=17561f&color=fafbff&name=' + user.name"
            class="image has-max-width is-aligned-center"
          />
          <hr>
          <b-field label="Name">
            <b-input
              :value="user.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Email">
            <b-input
              :value="user.email"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Country">
            <b-input
              :value="user.iso_code"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Country Code">
            <b-input
              :value="user.dial_code"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Phone">
            <b-input
              :value="user.phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Role">
            <b-input
              :value="user.role"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Active Status">
            <b-taglist>
              <b-tag
                v-if="user.is_active"
                type="is-success"
              >
                active
              </b-tag>
              <b-tag
                v-else
                type="is-danger"
              >
                inactive
              </b-tag>
            </b-taglist>
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import ManageUsers from '@/components/dashboard/ManageUsers'

export default {
  name: 'ClientForm',
  components: {
    UserAvatar,
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    ManageUsers
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isProfileExists: false,
      isLoading: false,
      createdReadable: null,
      user: null
    }
  },
  computed: {
    titleStack () {
      return [
        'Admin',
        'Users',
        this.user ? this.user.name : 'User Name'
      ]
    },
    heroTitle () {
      return this.user ? this.user.name : 'User Information'
    }
  },
  created () {
    this.fetchUser()
  },
  methods: {
    fetchUser () {
      this.isLoading = true
      this.axios.get('/v1/super-admin/users/' + this.$route.params.id)
        .then(response => {
          this.user = response.data
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    }
  }
}
</script>
